<template>
  <div style="padding: 20px">
    <Breadcrumb class="custom-breadcrumb" ref="breadcrumb">
      <BreadcrumbItem>员工互评管理</BreadcrumbItem>
      <BreadcrumbItem>评价者</BreadcrumbItem>
    </Breadcrumb>
    <div style="display: flex;margin-top: 10px">
      <div class="left">
        <div class="top">
          <Icon type="ios-git-network" style="color: royalblue;margin-right: 12px;transform: rotate(180deg)" />
          <span>部门</span>
        </div>
        <div class="tree">
          <Tree :data="treeData" @on-select-change="getAppraiserByTree" />
        </div>
      </div>
      <div class="right">
        <Table :loading="loading" :columns="columns" :data="tableData" stripe :height="tableH" @on-row-click="toAppraiseList">
          <template slot-scope="{ row }" slot="name">
            <span>{{ row.realName }}</span>
          </template>
          <template slot-scope="{ row }" slot="op">
            <div style="cursor: pointer" @click.stop="showAdd(row)">
              <Icon type="ios-add-circle-outline" style="font-size: 16px" />
              <span style="font-size: 14px;margin-left: 4px">添加被评价用户</span>
            </div>
          </template>
        </Table>
        <div style="display: flex;justify-content: space-between;align-items: center;padding: 16px 20px">
          <Button
            :disabled="appraiserData.length === 0 && userData.length === 0"
            @click="
              step = 1
              addModal = true
            "
            icon="ios-add-circle-outline"
            type="primary"
            >新增评价用户</Button
          >
          <Page @on-change="changePage" @on-page-size-change="changeSize" :total="total" show-sizer show-elevator show-total />
        </div>
      </div>
    </div>
    <Modal v-model="addModal" @on-cancel="cancel" :width="step === 3 ? 1280 : 485">
      <div slot="header" style="font-size: 20px;color: #3A4E64;text-align: center">
        {{ step === 3 ? '选择评价问卷' : step === 2 ? '添加被评价用户' : '添加评价用户' }}
      </div>
      <div>
        <div style="text-align: center;color: #5E6470;font-size: 12px">
          {{ step === 3 ? '从以下选择评价试卷' : step === 2 ? '从该部门选择被评价用户' : '从该部门选择评价用户' }}
        </div>
        <div v-if="step === 1" class="user">
          <RadioGroup v-model="appraiserId" style="width: 100%">
            <Radio
              :style="{
                background: appraiserId === item.userId ? '#EBF0F6' : 'white'
              }"
              :label="item.userId"
              v-for="(item, index) in appraiserData"
              :key="index"
              class="list"
            >
              <Avatar
                :src="item.avatar ? item.avatar : require('../../../assets/defaultAvatar.svg')"
                :size="22"
                style="margin: 0 8px 0 16px"
              ></Avatar>
              <span style="font-size: 14px">{{ item.realName }}</span>
            </Radio>
          </RadioGroup>
        </div>
        <div v-if="step === 2" class="user">
          <CheckboxGroup v-model="userIds" v-if="userData.length !== 0">
            <Checkbox
              :style="{
                background: userIds.indexOf(item.userId) > -1 ? '#EBF0F6' : 'white'
              }"
              :label="item.userId"
              v-for="(item, index) in userData"
              :key="index"
              class="list"
            >
              <Avatar
                :src="item.avatar ? item.avatar : require('../../../assets/defaultAvatar.svg')"
                :size="22"
                style="margin: 0 8px 0 16px"
              ></Avatar>
              <span style="font-size: 14px">{{ item.realName }}</span>
            </Checkbox>
          </CheckboxGroup>
          <div v-else>
            暂无可被添加的评价用户
          </div>
        </div>
        <div v-if="step === 3" class="selectTable">
          <div class="top">
            <div>
              <Select v-model="paperId" style="width:200px" @on-change="selectBank">
                <Option v-for="item in testPapers" :value="item.paperBankId" :key="item.paperBankId">{{ item.name }}</Option>
              </Select>
              <Radio disabled :value="selectPaperId !== ''" style="margin-left: 35px">已选择</Radio>
            </div>
            <Input search placeholder="请输入查找信息" class="search" v-model="keyword" @on-search="search" />
          </div>
          <Table
            highlight-row
            @on-current-change="selectPaper"
            :height="tableH - 90"
            :data="paperData"
            :columns="paperColumns"
            :loading="paperLoading"
            stripe
          >
            <template slot-scope="{ row }" slot="radio">
              <Radio :value="row.paperId === selectPaperId"></Radio>
            </template>
            <template slot-scope="{ row }" slot="level">
              <span>{{ checkLevel(row.level) }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="cTime">
              <span>{{ row.createTime | formatTime('yyyy-MM-dd hh:mm') }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="uTime">
              <span>{{ row.updateTime | formatTime('yyyy-MM-dd hh:mm') }}</span>
            </template>
          </Table>
        </div>
      </div>
      <div slot="footer" style="text-align: center">
        <Button style="width: 90px" @click="cancel">取消</Button>
        <Button
          :disabled="step === 1 ? appraiserId === '' : userIds.length === 0"
          type="primary"
          style="width: 90px;margin-left: 10px"
          @click="next"
          :loading="confirmLoading"
          >{{ step === 3 ? '确定' : '下一步' }}</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import department from '@api/department'
import exam from '@api/exam'
import paper from '@api/paper'
import { difficulty } from '@util/difficulty'

export default {
  name: 'Appraiser',
  data() {
    return {
      step: 1,
      appraiserData: [],
      keyword: '',
      userIds: [],
      paperLoading: false,
      paperData: [],
      loading: false,
      paperId: '',
      testPapers: [],
      addModal: false,
      tableH: document.body.clientHeight - 204,
      userData: [],
      treeData: [],
      paperColumns: [
        {
          title: '#',
          slot: 'radio',
          width: 60,
          align: 'center'
        },
        {
          title: '问卷名',
          key: 'name'
        },
        {
          title: '总分',
          key: 'totalPoints'
        },
        {
          title: '题量',
          key: 'questionAmount'
        },
        {
          title: '难度',
          slot: 'level'
        },
        {
          title: '创建时间',
          slot: 'cTime'
        },
        {
          title: '修改时间',
          slot: 'uTime'
        },
        {
          title: '创建人',
          key: 'creatorName'
        }
      ],
      columns: [
        {
          type: 'index',
          width: 60,
          align: 'center'
        },
        {
          title: '用户名',
          slot: 'name'
        },
        {
          title: '岗位',
          key: 'postName'
        },
        {
          title: '总评价人数',
          key: 'totalAppraiseUser'
        },
        {
          title: '未评价人数',
          key: 'unappraisedUser'
        },
        {
          title: '操作',
          slot: 'op'
        }
      ],
      tableData: [],
      total: 0,
      page: 0,
      size: 10,
      departmentId: '',
      selectPaperId: '',
      appraiserId: '',
      confirmLoading: false,
      initPaperBankId: '',
      initPaperData: []
    }
  },
  created() {
    department.getDepartmentsTree().then(res => {
      let arr = JSON.stringify(res.res.children)
      this.treeData = JSON.parse(arr.replace(/name/g, 'title'))
      this.setExpand(this.treeData[0])
    })
    paper.getAllPaperBanks().then(res => {
      this.testPapers = res.res
      if (res.res.length > 0) {
        this.initPaperBankId = res.res[0].paperBankId
        this.paperId = res.res[0].paperBankId
        this.getPapers(res.res[0].paperBankId, true)
      }
    })
  },
  methods: {
    setExpand(children) {
      if (!children.children) {
        return
      }
      if (children.children.length === 0) {
        this.getAppraiserByTree([children])
        children.selected = true
        return
      }
      children.expand = true
      this.setExpand(children.children[0])
    },
    showAdd(data) {
      this.step = 2
      this.appraiserId = data.userId
      this.addModal = true
    },
    selectPaper(data) {
      this.selectPaperId = data.paperId
    },
    checkLevel(num) {
      return difficulty[num].label
    },
    search() {
      if (this.paperId === '') {
        this.$message.warning('请选择问卷库')
        return
      }
      this.paperLoading = true
      paper
        .searchPaper(0, 10000, {
          paperBankId: this.paperId,
          name: this.keyword
        })
        .then(res => {
          this.paperData = res.res.data
          this.paperLoading = false
        })
        .catch(() => {
          this.paperLoading = false
        })
    },
    selectBank(val) {
      this.paperId = val
      this.keyword = ''
      this.getPapers(val)
    },
    getPapers(id, init) {
      this.paperLoading = true
      paper
        .getAllPaperList(id)
        .then(res => {
          this.paperData = res.res.data
          this.paperLoading = false
          if (init) {
            this.initPaperData = res.res.data
          }
        })
        .catch(() => {
          this.paperLoading = false
        })
    },
    next() {
      // if (this.userIds.length === 0) {
      //   this.$message.error('请选择被评价者')
      //   return
      // }
      if (this.step === 1) {
        this.step = 2
      } else if (this.step === 2) {
        this.step = 3
      } else {
        if (this.selectPaperId === '') {
          this.$message.error('请选择问卷')
          return
        } else {
          const payload = {
            appraiserId: this.appraiserId,
            paperId: this.selectPaperId,
            toUserIds: this.userIds
          }
          this.confirmLoading = true
          exam
            .addAppraise(payload)
            .then(res => {
              this.$message.success('添加成功')
              this.getAppraiserList()
              this.cancel()
            })
            .catch(() => {
              this.confirmLoading = false
            })
        }
      }
    },
    toAppraiseList(val) {
      this.$router.push({
        path: '/appraise/appraisedPerson',
        query: { id: val.userId, dId: this.departmentId }
      })
    },
    changePage(val) {
      this.page = val - 1
      this.getAppraiserList()
    },
    changeSize(val) {
      this.size = val
      this.getAppraiserList()
    },
    getAppraiserList() {
      this.loading = true
      exam
        .getAllAppraise(this.departmentId, this.page, this.size)
        .then(res => {
          this.tableData = res.res.data
          this.total = res.res.total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    getAppraiserByTree(val) {
      if (val.length === 0) {
        return
      }
      this.departmentId = val[0].departmentId
      this.page = 0
      this.size = 10
      this.loading = true
      exam
        .getAllAppraise(val[0].departmentId, 0, 10)
        .then(res => {
          this.tableData = res.res.data
          this.total = res.res.total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
      exam.getRelated(val[0].departmentId).then(res => {
        this.userData = res.res
      })
      exam.getAppraise(val[0].departmentId).then(res => {
        this.appraiserData = res.res
      })
    },
    cancel() {
      this.appraiserId = ''
      this.addModal = false
      this.userIds = []
      this.paperData = this.initPaperData
      this.paperId = this.initPaperBankId
      this.keyword = ''
      this.selectPaperId = ''
      this.confirmLoading = false
    }
  }
}
</script>

<style scoped lang="less">
.left {
  min-width: 300px;
  overflow: auto;
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  .top {
    height: 56px;
    background: #e4e9ef;
    font-size: 16px;
    color: #3a4e64;
    font-weight: 400;
    padding: 16px 23px;
    display: flex;
    align-items: center;
  }
  .tree {
    padding: 15px 12px;
    text-align: left;
  }
}
.right {
  margin-left: 10px;
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  flex: auto;
}
.user {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(228, 233, 239, 1);
  opacity: 1;
  border-radius: 4px;
  overflow: auto;
  margin-top: 16px;
  padding-left: 8px;
  padding-right: 8px;
  max-height: calc(100vh - 325px);
  .list {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    margin: 6px;
    &:hover {
      background: #ebf0f6;
    }
  }
}
.selectTable {
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  margin-top: 16px;
  .top {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    .search {
      width: 300px;
      ::v-deep .ivu-input {
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;

        &:focus {
          box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
        }
      }
    }
  }
}
::v-deep .ivu-tree ul {
  font-size: 14px;
  color: #5e6470;
}
::v-deep .ivu-modal-body {
  padding: 0 50px 20px 50px;
}
::v-deep .ivu-modal-header {
  padding: 30px 16px 10px 16px;
}
</style>
