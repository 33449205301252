var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"20px"}},[_c('Breadcrumb',{ref:"breadcrumb",staticClass:"custom-breadcrumb"},[_c('BreadcrumbItem',[_vm._v("员工互评管理")]),_c('BreadcrumbItem',[_vm._v("评价者")])],1),_c('div',{staticStyle:{"display":"flex","margin-top":"10px"}},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"top"},[_c('Icon',{staticStyle:{"color":"royalblue","margin-right":"12px","transform":"rotate(180deg)"},attrs:{"type":"ios-git-network"}}),_c('span',[_vm._v("部门")])],1),_c('div',{staticClass:"tree"},[_c('Tree',{attrs:{"data":_vm.treeData},on:{"on-select-change":_vm.getAppraiserByTree}})],1)]),_c('div',{staticClass:"right"},[_c('Table',{attrs:{"loading":_vm.loading,"columns":_vm.columns,"data":_vm.tableData,"stripe":"","height":_vm.tableH},on:{"on-row-click":_vm.toAppraiseList},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.realName))])]}},{key:"op",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.showAdd(row)}}},[_c('Icon',{staticStyle:{"font-size":"16px"},attrs:{"type":"ios-add-circle-outline"}}),_c('span',{staticStyle:{"font-size":"14px","margin-left":"4px"}},[_vm._v("添加被评价用户")])],1)]}}])}),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"center","padding":"16px 20px"}},[_c('Button',{attrs:{"disabled":_vm.appraiserData.length === 0 && _vm.userData.length === 0,"icon":"ios-add-circle-outline","type":"primary"},on:{"click":function($event){_vm.step = 1
            _vm.addModal = true}}},[_vm._v("新增评价用户")]),_c('Page',{attrs:{"total":_vm.total,"show-sizer":"","show-elevator":"","show-total":""},on:{"on-change":_vm.changePage,"on-page-size-change":_vm.changeSize}})],1)],1)]),_c('Modal',{attrs:{"width":_vm.step === 3 ? 1280 : 485},on:{"on-cancel":_vm.cancel},model:{value:(_vm.addModal),callback:function ($$v) {_vm.addModal=$$v},expression:"addModal"}},[_c('div',{staticStyle:{"font-size":"20px","color":"#3A4E64","text-align":"center"},attrs:{"slot":"header"},slot:"header"},[_vm._v(" "+_vm._s(_vm.step === 3 ? '选择评价问卷' : _vm.step === 2 ? '添加被评价用户' : '添加评价用户')+" ")]),_c('div',[_c('div',{staticStyle:{"text-align":"center","color":"#5E6470","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.step === 3 ? '从以下选择评价试卷' : _vm.step === 2 ? '从该部门选择被评价用户' : '从该部门选择评价用户')+" ")]),(_vm.step === 1)?_c('div',{staticClass:"user"},[_c('RadioGroup',{staticStyle:{"width":"100%"},model:{value:(_vm.appraiserId),callback:function ($$v) {_vm.appraiserId=$$v},expression:"appraiserId"}},_vm._l((_vm.appraiserData),function(item,index){return _c('Radio',{key:index,staticClass:"list",style:({
              background: _vm.appraiserId === item.userId ? '#EBF0F6' : 'white'
            }),attrs:{"label":item.userId}},[_c('Avatar',{staticStyle:{"margin":"0 8px 0 16px"},attrs:{"src":item.avatar ? item.avatar : require('../../../assets/defaultAvatar.svg'),"size":22}}),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(item.realName))])],1)}),1)],1):_vm._e(),(_vm.step === 2)?_c('div',{staticClass:"user"},[(_vm.userData.length !== 0)?_c('CheckboxGroup',{model:{value:(_vm.userIds),callback:function ($$v) {_vm.userIds=$$v},expression:"userIds"}},_vm._l((_vm.userData),function(item,index){return _c('Checkbox',{key:index,staticClass:"list",style:({
              background: _vm.userIds.indexOf(item.userId) > -1 ? '#EBF0F6' : 'white'
            }),attrs:{"label":item.userId}},[_c('Avatar',{staticStyle:{"margin":"0 8px 0 16px"},attrs:{"src":item.avatar ? item.avatar : require('../../../assets/defaultAvatar.svg'),"size":22}}),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(item.realName))])],1)}),1):_c('div',[_vm._v(" 暂无可被添加的评价用户 ")])],1):_vm._e(),(_vm.step === 3)?_c('div',{staticClass:"selectTable"},[_c('div',{staticClass:"top"},[_c('div',[_c('Select',{staticStyle:{"width":"200px"},on:{"on-change":_vm.selectBank},model:{value:(_vm.paperId),callback:function ($$v) {_vm.paperId=$$v},expression:"paperId"}},_vm._l((_vm.testPapers),function(item){return _c('Option',{key:item.paperBankId,attrs:{"value":item.paperBankId}},[_vm._v(_vm._s(item.name))])}),1),_c('Radio',{staticStyle:{"margin-left":"35px"},attrs:{"disabled":"","value":_vm.selectPaperId !== ''}},[_vm._v("已选择")])],1),_c('Input',{staticClass:"search",attrs:{"search":"","placeholder":"请输入查找信息"},on:{"on-search":_vm.search},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('Table',{attrs:{"highlight-row":"","height":_vm.tableH - 90,"data":_vm.paperData,"columns":_vm.paperColumns,"loading":_vm.paperLoading,"stripe":""},on:{"on-current-change":_vm.selectPaper},scopedSlots:_vm._u([{key:"radio",fn:function(ref){
            var row = ref.row;
return [_c('Radio',{attrs:{"value":row.paperId === _vm.selectPaperId}})]}},{key:"level",fn:function(ref){
            var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.checkLevel(row.level)))])]}},{key:"cTime",fn:function(ref){
            var row = ref.row;
            var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatTime")(row.createTime,'yyyy-MM-dd hh:mm')))])]}},{key:"uTime",fn:function(ref){
            var row = ref.row;
            var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatTime")(row.updateTime,'yyyy-MM-dd hh:mm')))])]}}],null,false,23157930)})],1):_vm._e()]),_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{staticStyle:{"width":"90px"},on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('Button',{staticStyle:{"width":"90px","margin-left":"10px"},attrs:{"disabled":_vm.step === 1 ? _vm.appraiserId === '' : _vm.userIds.length === 0,"type":"primary","loading":_vm.confirmLoading},on:{"click":_vm.next}},[_vm._v(_vm._s(_vm.step === 3 ? '确定' : '下一步'))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }